import React from 'react'
import NavBar from './NavBar'
import NavSmall from './NavSmall'
function Header() {
  return (
    <>
      <header className="header">
        <div className="secondary-nav">
          <b>+254 (0) 745 39 00 84</b>
        </div>
        <NavBar />
        <NavSmall />
      </header>
    </>
  );
}

export default Header;


