import React from 'react'
import knec from '../../static/knec.jpg'
import kasneb from '../../static/kasneb.png'
import kism from '../../static/kism.png'

const ExamBodies = () => {
    return (
        <div>
            <div className="content">
            <center>
                <div className="msg">
                    <p>
                        Do you love to question and have an appetite for knowledge? Are you looking for an exceptional education in an environment which values individuals for who they are? Turkana College might be the place for you.
                    </p>
                </div>
                <h2>Examination Bodies</h2>
                <div className="exam-bodies">
                    <img src={kasneb} alt="KASNEB" />
                    <img src={knec} alt="KNEC" />
                    <img src={kism} alt="KISM" />
                </div>
            </center>
            </div>
        </div>
    )
}

export default ExamBodies
