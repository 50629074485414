import React from 'react'
const SchoolTable  = ({ school }) => {
    return (

        <div>
            <h3>{school.name}</h3>
            <div className="school">
            <table>
                <thead>
                    <tr>
                        <th>Course</th>
                        <th>Minimum Qualifications</th>
                        <th>Exam Body</th>
                        <th>Duration</th>
                        <th>Tuition <br/><small>Per Term (KSH)</small></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        school.courses.map(course => (
                            <tr key={course.name}>
                                <td>{course.name}</td>
                                <td>{course.minQualifications}</td>
                                <td>{course.examBody}</td>
                                <td>{course.duration}</td>
                                <td>{course.tuitionPerTerm}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            </div>
        </div>
    )
}

export default SchoolTable