import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

const NavOptions = ({ handleToggle, history }) => {
    const current = history.location.pathname.replace('/', '')
    const active = current ? current : 'home'
    const [activeItem, setActiveItem] = useState(active)
    const navOptions = [
        'home',
        'about',
        'admissions',
        'schools'
    ]

    const handleClick = e => {
        handleToggle && handleToggle()
        const clickedNav = e.target.getAttribute('nav')
        setActiveItem(clickedNav)

        clickedNav === 'home' ? history.push('/') : history.push(clickedNav)
    }

    return (
        navOptions.map(opt => (
            <div
                className={`nav-option ${activeItem === opt ? 'clicked' : ''}`}
                onClick={handleClick}
                nav={opt}
                key={opt}
            >
                {opt}
            </div>
        ))
    )
}

export default withRouter(NavOptions)