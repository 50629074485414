import React from 'react'
import schools from './schools'
import SchoolTable from './SchoolTable'

const Schools = () => {
    return (
      <div className="page">
      <div className="section-header">
          <div className="content">
                <h1>Schools</h1>
                <p>Our schools provide a safe and supportive environment so you can focus on your studies, enjoy time with friends and make the most of all the opportunities on offer.</p>
          </div>
      </div>
        <div className="page-content">
            <div className="brief">
                We are made up of 3 schools. These schools are at the heart of Turkana Colege's reputation as one of the best tertiary institutions in Turkana County and make it a very special place to study. Every student belongs to a school as well as being a member of their course department and the college. This means all students have the benefits of belonging to both a large and to a small and friendly academic community.
                <br />
                <br />
                Students mix freely across schools and you are welcome to have friends over or invite them to some school events. Inter-school sport and drama can be a fun way to meet people from other schools. You will also meet other people not at your school in your course department and at college-wide events. If you come here, you are likely to spend lots of time in your school, particularly in your first year.
                
            </div>
            {
                schools.map(school => <SchoolTable key={school.name} school={school} />)
            }
        </div>
      </div>
    );
  }
  
  export default Schools;
  