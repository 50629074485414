import React from 'react'

function About() {
    return (
      <div className="page">
      <div className="section-header">
        <div className="content">
            <h1>About</h1>
            <p>Incorporated on the 16th April 2020 in accordance with the TVET Act, 2013 section  20 (1), Turkana College of Technical and Business Studies (TCTBS) is a reputable private tertiary training institution. It is registered as a technical and vocational college.</p>
        </div>
      </div>
        <div className="page-content">
            <div className="brief">
            Turkana College of Technical and Business Studies (TCTBS), as an Autonomous Business, Technical and Vocational Training institution, has always been taking the right initiative in the direction of improvement in the quality and content of the educational programmes. The general education, professional education and skill based vocational education, within the framework of the semester system are fulfilling the requirements of Technical and Vocational Education Training (TVET) body in Kenya. We are always geared up for the approaching Academics with renewed vigour and encouragement. The college Staff and the Management, are working persistently towards improving both teaching and infrastructure. Come and experience the rich academic tradition of the college.
            
            
            Turkana College of Technical and Business Studies was founded in the year 2017 as Kitale College of Business Studies by The Late John Lokaale Aule who was the first Director. The College has two Campuses,one in Lodwar located near The ministry of Tourism and Culture offices, the other campus is in Kakuma, located on the Kakuma Kalobeyei Highway. The College formulates its own courses of studies and evaluates its own students separately. The College faculty, therefore, after great deliberations and careful planning has developed courses of studies most appropriate under the prevailing circumstances. Diploma and Certificate Programs have been started to impart technical skills to students to procure middle-level jobs or to start their own business after graduating. The timings of these programs are such as to enable students of the College to enroll themselves without missing their classes. Regular attendance (75%) in the classroom has been made compulsory and the Semester System of teaching and evaluation has been introduced in the College since the academic session 2017-2018 when the college started, Entire College programs, thus, have been oriented towards maintaining high academic standards, acquisition of knowledge and skills rather than merely passing the examinations. The medium of instruction is mostly English. The large eco-friendly campus, host of other extra-curricular activities encourage students to develop a spirit of inquiry and enrich their lives meaningfully to provide excellent Student Support Services. It is expected that students seeking admission in this College will take admission, if selected, fully aware of its Minority and Autonomous Status, and will be more interested in acquiring knowledge and skills by regularly attending classes rather than merely for the purpose of passing the examinations for their Diplomas and Certificate courses.
            </div>
        </div>
      </div>
    );
  }
  
  export default About;
  