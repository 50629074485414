import React from 'react'

const Footer = () => {
    return (
      <>
        <footer className="footer">
            <div className="content footer-items">
                <div className="footer-item">
                    <b>About us</b>
                    <p>Incorporated on the 16th April 2020 in accordance with the TVET Act, 2013 section  20 (1), Turkana College of Technical and Business Studies (TCTBS) is a reputable private tertiary training institution. It is registered as a technical and vocational college. </p>
                </div>
                <div className="footer-item">
                    <b>Contacts</b>
                    <br/>
                    <br/>
                    <address>
                        Turkana College of Technical<br />
                        and Business Studies<br />
                        PO Box 203-30500<br />
                        Lodwar, Kenya<br />
                        <br />
                        P: +254 (0) 745 39 00 84<br /> 
                        +254 (0) 797 69 22 00<br />
                        E: info@turkanacollege.ac.ke
                    </address>
                </div>
            </div>
        </footer>
      </>
    );
  }
  
  export default Footer;
  
  
  