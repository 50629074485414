import React from 'react'
import { withRouter } from 'react-router-dom'
import NavOptions from './NavOptions'

import logo from '../../../static/logo.jpg';

const NavBar = ({ history }) => {
    const handleHomeClick = () => {
        history.push('/')
    }
    return (
        <nav className="navbar">
            <img src={logo} className="logo" alt="Turkana College of Technical and Business Studies" onClick={handleHomeClick} />
            <NavOptions />
        </nav>
    )
}

export default withRouter(NavBar)