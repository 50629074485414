const schools = [
    {
        name: "School of Information Communication",
        courses: [
            {
                name: "Diploma in ICT",
                minQualifications: 'C(Minus)',
                examBody:'KNEC',
                duration: '3 Years',
                tuitionPerTerm: 15000
            },
            {
                name: "Certificate in ICT",
                minQualifications: 'D(Plain)',
                examBody:'KNEC',
                duration: '1 Year',
                tuitionPerTerm: 12000
            },
            {
                name: "Certificate in Computer Repair and Maintainance",
                minQualifications: 'Open',
                examBody:'TCTBS',
                duration: '1 Year',
                tuitionPerTerm: 10000
            },
        ]
    },
    {
        name: "School of Health and Social Sciences",
        courses: [
            {
                name: "Certificate in Social Development and Welfare",
                minQualifications: 'D(Plain)',
                examBody:'KNEC',
                duration: '1 Year',
                tuitionPerTerm: 12000
            },
            {
                name: "Diploma in Social Work",
                minQualifications: 'C(Minus)',
                examBody:'KNEC',
                duration: '3 Years',
                tuitionPerTerm: 15000
            },
            {
                name: "Diploma in Community Development",
                minQualifications: 'C(Minus)',
                examBody:'KNEC',
                duration: '3 Years',
                tuitionPerTerm: 15000
            },
            {
                name: "Certificate in General Agriculture",
                minQualifications: 'D(Plain)',
                examBody:'KNEC',
                duration: '3 Years',
                tuitionPerTerm: 12000
            },
            {
                name: "Diploma in Health Record and ICT",
                minQualifications: 'C(Minus)',
                examBody:'KNEC',
                duration: '3 Years',
                tuitionPerTerm: 15000
            },
            {
                name: "Certificate in Health Records and ICT",
                minQualifications: 'D(Plain)',
                examBody:'KNEC',
                duration: '1 Year',
                tuitionPerTerm: 12000
            },
            {
                name: "Diploma in Public Administration and ICT",
                minQualifications: 'C(Minus)',
                examBody:'KNEC',
                duration: '3 Years',
                tuitionPerTerm: 15000
            },
            {
                name: "Certificate in Public Administration and ICT",
                minQualifications: 'D(Plain)',
                examBody:'KNEC',
                duration: '1 Year',
                tuitionPerTerm: 12000
            },
        ]
    },
    {
        name: "School of Business",
        courses: [
            {
                name: "Diploma in Human Resource Management",
                minQualifications: 'C(Minus)',
                examBody:'KNEC',
                duration: '3 Years',
                tuitionPerTerm: 15000
            },
            {
                name: "Certificate in Human Resource Management",
                minQualifications: 'D(Plain)',
                examBody:'KNEC',
                duration: '1 Year',
                tuitionPerTerm: 12000
            },
            {
                name: "Diploma in Secretarial Studies",
                minQualifications: 'C(Minus)',
                examBody:'KNEC',
                duration: '3 Years',
                tuitionPerTerm: 15000
            },
            {
                name: "Certificate in Secretarial Studies",
                minQualifications: 'D(Plain)',
                examBody:'KNEC',
                duration: '1 Year',
                tuitionPerTerm: 12000
            },
            {
                name: "Diploma in Supply Chain Management",
                minQualifications: 'C(Minus)',
                examBody:'KNEC',
                duration: '3 Years',
                tuitionPerTerm: 15000
            },
            {
                name: "Certificate in Supply Chain Management",
                minQualifications: 'D(Plain)',
                examBody:'KNEC',
                duration: '1 Year',
                tuitionPerTerm: 12000
            },
            {
                name: "Diploma in Accountancy",
                minQualifications: 'C(Minus)',
                examBody:'KNEC',
                duration: '2 Years',
                tuitionPerTerm: 15000
            },
            {
                name: "Certificate in Accountancy",
                minQualifications: 'D(Plain)',
                examBody:'KNEC',
                duration: '1 Year',
                tuitionPerTerm: 12000
            },
            {
                name: "Certificate in Artisan Storekeeping",
                minQualifications: 'KCPE',
                examBody:'KNEC',
                duration: '1 Year',
                tuitionPerTerm: 10000
            },
        ]
    }
]

export default schools