import React from 'react'
import ExamBodies from './ExamBodies'

const Home = () => {
    return (
      <div className="page">
        <div className="home">
          <div className="content">
              <div className="jumbo">
                  <h1>January 2024 intake in progress</h1>
                  <p>Select from the top quality courses we offer.</p>
              </div>
          </div>
        </div>
        <ExamBodies />
      </div>
    );
  }
  
  export default Home;
  