import React from 'react'
import { Route, Switch } from 'react-router-dom'

import './App.css';
import Header from './components/layout/header'
import Footer from './components/layout/footer'
import Home from './pages/home'
import About from './pages/about'
import Admissions from './pages/admissions'
import Schools from './pages/schools'


function App() {
  return (
    <div className="App">
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/admissions" component={Admissions} />
        <Route exact path="/schools" component={Schools} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
