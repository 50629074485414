import React from 'react'

function Admissions() {
    return (
      <div className="page">
        <div className="section-header">
            <div className="content">
                <h1>Admissions</h1>
                <p>Do you love to question and have an appetite for knowledge? Are you looking for an exceptional education in an environment which values individuals for who they are? Turkana College might be the place for you.</p>
            </div>
        </div>
        <div className="page-content">
                <h1>Student life</h1>
            <div className="brief">
                If you study here, you will receive a great education - something which lasts a lifetime. Beyond that, the extraordinary range of opportunities allows you to choose your student life at Turkana College - so it really can be what you want it to be. There is no typical student here and no single Turkana College experience. At the end of their time here, we want our graduates to have the self-belief to be whoever they want to be and to feel confident and excited about their future.
            </div>
            <div className="admin">
                <div className="card">
                <h5>An exceptional education</h5>
                <p>
                Turkana College is among the best institution of learning in Turkana County because the teaching, research and learning resources are exceptional.
                </p>
                </div>
                <div className="card">
                <h5>A welcoming and supportive community</h5>
                <p>
                There are many different communities for you to choose from at Turkana College. As well as providing support, some are actively pioneering change.
                </p>
                </div>

                <div className="card">
                <h5>Do what you love</h5>
                <p>
                Pick and choose from the many clubs and societies to create your own amazing Turkana College experience.
                </p>
                </div>
                <div className="card">
                <h5>Turkana College is for you</h5>
                <p>
                The diversity of our students makes Turkana College one of the most exciting learning environments anywhere. Should you apply?
                </p>
                </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default Admissions;
  