import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import NavOptions from './NavOptions'

import logo from '../../../static/logo.jpg';

const NavSmall = ({ history }) => {
    const [ toggleMenu, setToggleMenu ] = useState(false)

    const handleToggle = () => {
        setToggleMenu(!toggleMenu)
    }

    const handleHomeClick = () => {
        history.push('/')
    }

    return (
        <nav className="nav-small">
            <button
                className='nav-button'
                onClick={handleToggle}
            >
                { toggleMenu ? 'Close' : 'Menu' }
            </button>
            <img src={logo} className="logo" alt="Turkana College of Technical and Business Studies" onClick={handleHomeClick}/>
            {toggleMenu ? <NavOptions handleToggle={handleToggle} /> : null}
        </nav>
    )
}

export default withRouter(NavSmall)